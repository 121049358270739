import { reduce } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { applyRulesForViewAsRecipients } from 'modules/validation';
import { selectSelectedRecipientIds } from 'modules/view-as/selectors';

import { addRecipient } from './actions';
import AddRecipientSpecificInformation from './components/add-recipient';
import RecipientSpecificSection from './components/section';
import {
  selectAllRecipients,
  selectAllowedPrimaryRecipients,
  selectAllowedSecondaryRecipients,
  selectCanHaveSpecificDataByRecipient,
  selectCanHaveSpecificFieldsByRecipient,
  selectHasSharingUnitsByRecipient,
  selectHasSpecificAssetsByRecipient,
  selectHasSpecificAttributesByRecipient,
  selectOverridableFieldsAvailableByRecipient,
  selectOverriddenFieldsByRecipient,
  // Sharing units
  selectSharingUnitsByRecipient,
  // Specific values and attributes.
  selectSpecificDataByRecipient,
  selectSpecificFieldsByRecipient,
} from './selectors';

const byRecipient = (recipient, maps) =>
  reduce(
    maps,
    (acc, item, key) => ({
      ...acc,
      [key]: item && item.get(recipient.get('id')),
    }),
    {},
  );

const mapStateToProps = createStructuredSelector({
  recipients: selectAllRecipients,
  selectedRecipientIds: selectSelectedRecipientIds,
  allowedPrimaryRecipients: selectAllowedPrimaryRecipients,
  allowedSecondaryRecipients: selectAllowedSecondaryRecipients,

  // by recipient
  specificData: selectSpecificDataByRecipient,
  canHaveSpecificData: selectCanHaveSpecificDataByRecipient,
  canHaveSpecificFields: selectCanHaveSpecificFieldsByRecipient,
  hasSpecificAttributes: selectHasSpecificAttributesByRecipient,
  overriddenFields: selectOverriddenFieldsByRecipient,
  overridableFields: selectOverridableFieldsAvailableByRecipient,
  specificFields: selectSpecificFieldsByRecipient,
  hasSharingUnits: selectHasSharingUnitsByRecipient,
  sharingUnits: selectSharingUnitsByRecipient,
  hasSpecificAssets: selectHasSpecificAssetsByRecipient,
});

const mapDispatchToProps = {
  addRecipient,
  applyRulesForViewAsRecipients,
};

export class RecipientSpecificInformation extends PureComponent {
  static propTypes = {
    recipients: ImmutablePropTypes.list.isRequired,
    selectedRecipientIds: PropTypes.array,
    allowedPrimaryRecipients: ImmutablePropTypes.list.isRequired,
    allowedSecondaryRecipients: ImmutablePropTypes.list.isRequired,
    addRecipient: PropTypes.func.isRequired,
    applyRulesForViewAsRecipients: PropTypes.func.isRequired,

    specificData: ImmutablePropTypes.map.isRequired,
    canHaveSpecificData: ImmutablePropTypes.map.isRequired,
    canHaveSpecificFields: ImmutablePropTypes.map.isRequired,
    hasSpecificAttributes: ImmutablePropTypes.map.isRequired,
    overriddenFields: ImmutablePropTypes.map.isRequired,
    overridableFields: ImmutablePropTypes.map.isRequired,
    specificFields: ImmutablePropTypes.map.isRequired,
    hasSharingUnits: ImmutablePropTypes.map.isRequired,
    sharingUnits: ImmutablePropTypes.map.isRequired,
    hasSpecificAssets: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    selectedRecipientIds: null,
  };

  onSelectRecipient = (recipient) => {
    this.props.addRecipient(recipient);
    this.props.applyRulesForViewAsRecipients();
  };

  render() {
    const {
      recipients,
      selectedRecipientIds,
      allowedPrimaryRecipients,
      allowedSecondaryRecipients,
      specificData,
      canHaveSpecificData,
      canHaveSpecificFields,
      hasSpecificAttributes,
      overriddenFields,
      overridableFields,
      specificFields,
      hasSharingUnits,
      sharingUnits,
      hasSpecificAssets,
    } = this.props;

    if (!recipients || recipients.size === 0) {
      return null;
    }

    let filteredRecipients = recipients;
    if (selectedRecipientIds && selectedRecipientIds.length > 0) {
      filteredRecipients = filteredRecipients.filter((r) =>
        selectedRecipientIds.includes(r.get('id')),
      );
    }

    return (
      <>
        {filteredRecipients
          .map((recipient) => (
            <RecipientSpecificSection
              key={recipient.get('id')}
              recipient={recipient}
              isPrimary={
                !!allowedPrimaryRecipients.find(
                  (r) => r.get('id') === recipient.get('id'),
                )
              }
              isSelectedInViewAs={
                selectedRecipientIds &&
                selectedRecipientIds.includes(recipient.get('id'))
              }
              {...byRecipient(recipient, {
                specificData,
                canHaveSpecificData,
                canHaveSpecificFields,
                hasSpecificAttributes,
                overriddenFields,
                overridableFields,
                specificFields,
                hasSharingUnits,
                sharingUnits,
                hasSpecificAssets,
              })}
            />
          ))
          .toArray()}
        <AddRecipientSpecificInformation
          primaryRecipients={allowedPrimaryRecipients}
          secondaryRecipients={allowedSecondaryRecipients}
          onSelect={this.onSelectRecipient}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecipientSpecificInformation);
