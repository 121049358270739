import Immutable from 'immutable';
import { get, isNumber, isUndefined } from 'lodash';
import { compose } from 'redux';
import { createReducer } from 'redux-create-reducer';

import { ENTITY_TYPE_SHARINGUNIT_TARIFF } from 'constants/entities';
import { UPDATE_ENTITY } from 'constants/events/entity';
import {
  initSelectedFilterState,
  updateSelectedFilters,
} from 'core/modules/list/reducers/filters';
import {
  collapseFilters,
  initFiltersConfigState,
} from 'core/modules/list/reducers/filters-config';
import { updateAggregations } from 'core/modules/list/utils/aggregations';
import {
  onAddBracket,
  onRemoveAllBrackets,
} from 'modules/price-waterfalls/utils';
import { RuleApplicationStatus, restrictionTypes } from 'modules/validation';
import { calculateOffset } from 'utils';
import { set, update } from 'utils/immutable';

import {
  // Accept
  ACCEPT,
  ACCEPT_FAILURE,
  ACCEPT_SUCCESS,
  // Product
  ADD_PRODUCT_SUCCESS,
  CLEAR_STORE,
  CLOSE_DUPLICATE_MODAL,
  CLOSE_PUBLISH_MODAL,
  CLOSE_REJECT_MODAL,
  COLLAPSE_FILTERS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  DUPLICATE,
  DUPLICATE_FAILURE,
  DUPLICATE_SUCCESS,
  ERROR_SEARCHING_PRODUCT,
  // Export
  EXPORT,
  EXPORT_BULK,
  EXPORT_BULK_FINISHED,
  EXPORT_BULK_PROGRESS,
  EXPORT_BULK_TARIFF_ONE_FILE,
  EXPORT_CANCEL,
  EXPORT_FAILURE,
  EXPORT_FORMAT,
  EXPORT_SUCCESS,
  FETCH_DISPLAY_GROUPS_SUCCESS,
  // Products
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  // Publication report
  FETCH_PUBLICATION_RESULTS,
  FETCH_PUBLICATION_RESULTS_FAILURE,
  FETCH_PUBLICATION_RESULTS_SUCCESS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_SUCCESS,
  // Filters
  FILTERS_CONFIG_KEY,
  HIERARCHIES_RELOADED,
  OPEN_DUPLICATE_MODAL,
  OPEN_PUBLISH_MODAL,
  // Reject
  OPEN_REJECT_MODAL,
  POLL_PRODUCTS,
  PRODUCTS_POLLING_FINISHED,
  PUBLISH,
  PUBLISH_FAILURE,
  PUBLISH_SUCCESS,
  REJECT,
  REJECT_FAILURE,
  REJECT_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
  SEARCH_LIST,
  SELECT_RECIPIENT,
  SELECT_TARIFFS_IN_LIST,
  Statuses,
  UNSELECT_TARIFFS_IN_LIST,
  UPDATE_CURRENT_PAGE,
  UPDATE_CURRENT_PRODUCTS_PAGE,
  UPDATE_FILTER_IN_ERROR,
  UPDATE_FILTER_PAGE,
  UPDATE_FILTER_SEARCH,
  UPDATE_SEARCH,
  UPDATE_SELECTED_FILTERS,
  VALIDATE,
  VALIDATE_FAILURE,
  VALIDATE_SUCCESS,
} from './constants';

const initialState = Immutable.Map({
  // Detail
  recipientId: null,

  displayGroups: [],
  editDisplayGroups: [],

  id: null,
  uuid: null,
  data: {},
  customData: {
    hasBrackets: false,
  },
  name: '',
  status: '',
  type: 0, // default to tariffs
  sourceOrganization: null,
  updatedAt: null,
  dataUpdatedAt: null,
  rejectionReason: null,
  isTemplatePublishing: false,
  hasFailedPublication: false,

  isDirty: false,
  isSaving: false,
  isDeleting: false,
  isDuplicating: false,
  isPublishing: false,
  isValidating: false,
  validationResult: null,
  failedBlockingHeader: 0,

  // -- Products
  products: Immutable.Map({
    isLoading: false,
    isPolling: false,
    products: [],
    search: '',
    errorInProductSearch: false,
    filterInError: false,
    totalInError: 0,
    hierarchies: {},
    pagination: {
      totalResults: 0,
      currentPage: 1,
      totalPages: 1,
      limit: 20,
    },
  }),

  // -- Product
  isAddingProduct: false,

  // List
  list: Immutable.List(),
  isFetching: false,
  filterSearch: Immutable.Map(),
  listFilterPages: Immutable.Map({
    'recipient-filter': 1,
  }),
  pagination: Immutable.Map({
    totalResults: 0,
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  }),
  selectedTariffIds: [],
  selectedTariffUuids: [],
  sourceOrganizations: [],
  aggregations: Immutable.Map(),
  search: '',

  // Export
  isExporting: false,
  bulkExportProgress: [],
  exportFormat: undefined,

  // Reject
  rejectModalOpen: false,
  isRejecting: false,

  // Accept
  isAccepting: false,

  // Duplicate
  duplicateModalOpen: false,

  // Publication report
  publicationReport: Immutable.Map({
    fetching: false,
    results: [],
    totalResults: null,
  }),
});

const resetPagination = (state) =>
  state.update('pagination', (pagination) => ({
    ...pagination,
    currentPage: 1,
    offset: 0,
  }));

const enhancedInitialState = compose(
  initFiltersConfigState(FILTERS_CONFIG_KEY),
  initSelectedFilterState,
)(initialState);

export default createReducer(enhancedInitialState, {
  [CLEAR_STORE]: () => enhancedInitialState,

  [SELECT_RECIPIENT]: (state, { recipientId, templateType }) =>
    state
      .set('recipientId', recipientId)
      .update('type', (t) => (!isUndefined(templateType) ? templateType : t)),

  [FETCH_DISPLAY_GROUPS_SUCCESS]: (state, { displayGroups }) =>
    state.set('displayGroups', displayGroups),

  /* Template edition */
  [UPDATE_ENTITY]: (state, { entityKind, key, value, isDirty }) => {
    if (entityKind !== ENTITY_TYPE_SHARINGUNIT_TARIFF) {
      return state;
    }
    if (key === '__full_data__.name') {
      return state
        .set('name', value)
        .update('isDirty', (current) => current || (isDirty && Boolean(value)));
    }
    if (key === 'hasBrackets') {
      let newState = state.update('customData', (d) =>
        update(d, 'hasBrackets', () => value),
      );
      if (value) {
        // Add the second bracket automatically.
        newState = newState
          .update('data', (data) =>
            update(data, 'priceWaterfalls.0', (pw) => onAddBracket(pw)),
          )
          .set('isDirty', true);
      } else {
        // Remove all but one bracket.
        newState = newState
          .update('data', (data) =>
            update(data, 'priceWaterfalls.0', (pw) => onRemoveAllBrackets(pw)),
          )
          .set('isDirty', true);
      }
      return newState;
    }
    const newData = set(state.get('data'), key, value);
    return (
      state
        .set('data', newData)
        // Set to false if we delete the next to last bracket.
        .update('customData', (d) =>
          update(
            d,
            'hasBrackets',
            () => (get(newData, 'priceWaterfalls.0.brackets') || []).length > 1,
          ),
        )
        .set('isDirty', isDirty)
    );
  },

  /* */
  [SAVE]: (state) => state.set('isSaving', true),

  [SAVE_SUCCESS]: (state, { template }) =>
    state
      .set('data', template.data)
      .set('updatedAt', template.updatedAt)
      .set('dataUpdatedAt', template.data_updated_at)
      .set('isDirty', false)
      .set('id', template.id)
      .set('uuid', template.uuid)
      .set('name', template.name)
      .set('status', template.status)
      .set('type', template.type)
      .set('sourceOrganization', Immutable.fromJS(template.sourceOrganization))
      .set('isSaving', false),

  [SAVE_FAILURE]: (state) => state.set('isDirty', false).set('isSaving', false),

  [FETCH_TEMPLATE]: (state) => state.set('isFetching', true),
  [FETCH_TEMPLATE_SUCCESS]: (state, { template }) =>
    state
      .set('data', template.data)
      .set('updatedAt', template.updatedAt)
      .set('dataUpdatedAt', template.data_updated_at)
      .set('isDirty', false)
      .set('id', template.id)
      .set('uuid', template.uuid)
      .set('name', template.name)
      .set('rejectionReason', template.rejectionReason)
      .set('status', template.status)
      .set('type', template.type)
      .set('isTemplatePublishing', template.publishing)
      .set('hasFailedPublication', template.has_failed_publication)
      .set('sourceOrganization', Immutable.fromJS(template.sourceOrganization))
      .set('isFetching', false)
      .update('customData', (d) =>
        update(
          d,
          'hasBrackets',
          () =>
            (get(template, 'data.priceWaterfalls.0.brackets') || []).length > 1,
        ),
      ),
  [FETCH_TEMPLATE_FAILURE]: (state) => state.set('isFetching', false),

  [OPEN_PUBLISH_MODAL]: (state) => state.set('publishModalOpen', true),
  [CLOSE_PUBLISH_MODAL]: (state) => state.set('publishModalOpen', false),
  [PUBLISH]: (state) => state.set('isPublishing', true),
  [PUBLISH_SUCCESS]: (state) =>
    state
      .set('status', Statuses.PUBLISHED.id)
      .set('isPublishing', false)
      .set('publishModalOpen', false),
  [PUBLISH_FAILURE]: (state) =>
    state.set('isPublishing', false).set('publishModalOpen', false),

  [DELETE]: (state) => state.set('isDeleting', true),
  [DELETE_SUCCESS]: (state) => state.set('isDeleting', false),
  [DELETE_FAILURE]: (state) => state.set('isDeleting', false),

  [OPEN_DUPLICATE_MODAL]: (state) => state.set('duplicateModalOpen', true),
  [CLOSE_DUPLICATE_MODAL]: (state) => state.set('duplicateModalOpen', false),
  [DUPLICATE]: (state) => state.set('isDuplicating', true),
  [DUPLICATE_SUCCESS]: (state) =>
    state.set('isDuplicating', false).set('duplicateModalOpen', false),
  [DUPLICATE_FAILURE]: (state) =>
    state.set('isDuplicating', false).set('duplicateModalOpen', true),

  [VALIDATE]: (state) =>
    state.set('isValidating', true).set('validationResult', {}),

  [VALIDATE_SUCCESS]: (state, { result }) => {
    const failedBlockingHeader = result.result.rules
      .filter(
        (r) =>
          r.status === RuleApplicationStatus.KO &&
          r.restrictionType === restrictionTypes.BLOCKING,
      )
      .filter(
        (r) =>
          !Object.values(r.paths).some((ps) =>
            ps.some((p) => p.toLowerCase().startsWith('sharingunits.')),
          ),
      );
    return state
      .set('validationResult', result)
      .set('isValidating', false)
      .set('failedBlockingHeader', failedBlockingHeader.length);
  },

  [VALIDATE_FAILURE]: (state) => state.set('isValidating', false),

  // Product
  [ADD_PRODUCT_SUCCESS]: (state) => state.set('isAddingProduct', false),
  [HIERARCHIES_RELOADED]: (state, { pkId, hierarchies }) =>
    state.update('products', (sub) =>
      sub.withMutations((s) => {
        s.update('hierarchies', (h) => ({ ...h, [pkId]: hierarchies }));
      }),
    ),

  [FETCH_PRODUCTS]: (state) =>
    state
      .setIn(['products', 'isLoading'], true)
      .setIn(['products', 'errorInProductSearch'], false),
  [FETCH_PRODUCTS_SUCCESS]: (
    state,
    { payload: { products, hierarchies, totalResults, totalInError } },
  ) =>
    state.update('products', (sub) =>
      sub.withMutations((s) => {
        const pagination = s.get('pagination');
        let totalPages = 1;
        if (isNumber(totalResults)) {
          totalPages = Math.ceil(totalResults / pagination.limit);
        }
        s.set('products', products || []);
        s.set('hierarchies', hierarchies || {});
        s.set('totalInError', totalInError || 0);
        s.set('pagination', {
          ...s.get('pagination'),
          totalResults: totalResults || 0,
          totalPages,
        });
        s.set('isLoading', false);
      }),
    ),
  [POLL_PRODUCTS]: (state) => state.setIn(['products', 'isPolling'], true),
  [PRODUCTS_POLLING_FINISHED]: (state) =>
    state.setIn(['products', 'isPolling'], false),
  [UPDATE_CURRENT_PRODUCTS_PAGE]: (state, { payload: currentPage }) =>
    state.updateIn(['products', 'pagination'], (pagination) => ({
      ...pagination,
      offset: calculateOffset(currentPage, pagination.limit),
      currentPage,
    })),
  [UPDATE_SEARCH]: (state, { payload: search }) =>
    state.update('products', (products) =>
      resetPagination(products.set('search', search)),
    ),
  [ERROR_SEARCHING_PRODUCT]: (state) =>
    state.setIn(['products', 'errorInProductSearch'], true),
  [UPDATE_FILTER_IN_ERROR]: (state, { payload: filterInError }) =>
    state.update('products', (products) =>
      resetPagination(products.set('filterInError', filterInError)),
    ),

  /* List */
  [UPDATE_FILTER_SEARCH]: (state, { payload }) =>
    state.setIn(['filterSearch', payload.key], payload.search),
  [COLLAPSE_FILTERS]: collapseFilters,
  [UPDATE_SELECTED_FILTERS]: (state, action) => {
    const newState = updateSelectedFilters(state, action);
    return newState.set(
      'pagination',
      Immutable.Map({
        totalResults: 0,
        currentPage: 1,
        totalPages: 1,
        limit: 20,
      }),
    );
  },
  [UPDATE_FILTER_PAGE]: (state, { payload: { key, page } = {} }) =>
    state.setIn(['listFilterPages', key], page),
  [UPDATE_CURRENT_PAGE]: (state, { payload: currentPage }) =>
    state.update('pagination', (pagination) =>
      pagination
        .set('currentPage', currentPage)
        .set('offset', calculateOffset(currentPage, pagination.get('limit'))),
    ),
  [FETCH_TEMPLATES]: (state) => state.set('isFetching', true),
  [FETCH_TEMPLATES_FAILURE]: (state) =>
    state.withMutations((s) => {
      s.set('isFetching', false);
      s.set(
        'pagination',
        Immutable.Map({
          totalResults: 0,
          currentPage: 1,
          offset: 0,
          totalPages: 1,
          limit: 20,
        }),
      );
      s.set('list', Immutable.List());
    }),
  [FETCH_TEMPLATES_SUCCESS]: (
    state,
    {
      templates,
      totalResults,
      sourceOrganizations,
      aggregations,
      fullAggregations,
    },
  ) =>
    state.withMutations((newState) => {
      newState.set('list', Immutable.fromJS(templates));
      newState.set('sourceOrganizations', sourceOrganizations || []);
      newState.set('isFetching', false);
      const pagination = newState.get('pagination');
      let totalPages = 1;
      if (isNumber(totalResults)) {
        totalPages = Math.ceil(totalResults / pagination.get('limit'));
      }
      newState.update('pagination', (p) =>
        p.set('totalResults', totalResults).set('totalPages', totalPages),
      );
      if (fullAggregations) {
        updateAggregations(newState, fullAggregations);
      }
      updateAggregations(newState, aggregations);
    }),
  [SELECT_TARIFFS_IN_LIST]: (state, { tariffIds, tariffUuids }) =>
    state
      .update('selectedTariffIds', (ids) => [...ids, ...tariffIds])
      .update('selectedTariffUuids', (uuids) => [...uuids, ...tariffUuids]),
  [UNSELECT_TARIFFS_IN_LIST]: (state, { tariffIds, tariffUuids }) =>
    state
      .update('selectedTariffIds', (ids) =>
        ids.filter((id) => !tariffIds.includes(id)),
      )
      .update('selectedTariffUuids', (uuids) =>
        uuids.filter((uuid) => !tariffUuids.includes(uuid)),
      ),
  [SEARCH_LIST]: (state, { payload }) => state.set('search', payload),

  // Export
  [EXPORT]: (state) => state.set('isExporting', true),
  [EXPORT_BULK_TARIFF_ONE_FILE]: (state) => state.set('isExporting', true),
  [EXPORT_SUCCESS]: (state) => state.set('isExporting', false),
  [EXPORT_FAILURE]: (state) => state.set('isExporting', false),
  [EXPORT_CANCEL]: (state) => state.set('bulkExportProgress', []),
  [EXPORT_BULK]: (state) => state.set('isExporting', true),
  [EXPORT_BULK_PROGRESS]: (state, { progress }) =>
    state.update('bulkExportProgress', (l) => {
      const index = l.findIndex(
        (e) => e.item.get('uuid') === progress.item.get('uuid'),
      );
      if (index >= 0) {
        return set(l, index, progress);
      }
      return [...l, progress];
    }),
  [EXPORT_BULK_FINISHED]: (state) => state.set('isExporting', false),
  [EXPORT_FORMAT]: (state, { payload: data }) =>
    state.set('exportFormat', data),

  // Reject
  [OPEN_REJECT_MODAL]: (state) => state.set('rejectModalOpen', true),
  [CLOSE_REJECT_MODAL]: (state) => state.set('rejectModalOpen', false),
  [REJECT]: (state) => state.set('isRejecting', true),
  [REJECT_SUCCESS]: (state) => state.set('isRejecting', false),
  [REJECT_FAILURE]: (state) => state.set('isRejecting', false),

  // Accept
  [ACCEPT]: (state) => state.set('isAccepting', true),
  [ACCEPT_SUCCESS]: (state) => state.set('isAccepting', false),
  [ACCEPT_FAILURE]: (state) => state.set('isAccepting', false),

  // Publication report
  [FETCH_PUBLICATION_RESULTS]: (state) =>
    state.setIn(['publicationReport', 'fetching'], true),
  [FETCH_PUBLICATION_RESULTS_FAILURE]: (state) =>
    state.setIn(['publicationReport', 'fetching'], false),
  [FETCH_PUBLICATION_RESULTS_SUCCESS]: (state, { results, totalResults }) => {
    return state.withMutations((s) => {
      if (results && results.length) {
        s.setIn(
          ['publicationReport', 'results'],
          s.getIn(['publicationReport', 'results']).concat(results),
        );
      }
      s.setIn(['publicationReport', 'totalResults'], totalResults);
      s.setIn(['publicationReport', 'fetching'], false);
    });
  },
});
