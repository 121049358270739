import { get, keyBy } from 'lodash';

import { OrganizationSource } from '@alkem/lib-front-model';

import { notificationError, notificationSuccess } from 'actions/notification';
import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import {
  EDIT_ORGANIZATION,
  GET_ORGANIZATION,
  ORGANIZATION_CGU_ACCEPTED,
  RECEIVE_ORGANIZATION,
  RECEIVE_ORGANIZATION_CGU,
  RECEIVE_ORGANIZATION_SETTING,
  RECEIVE_ORGANIZATION_SETTINGS,
  UPDATE_ORGANIZATION_SETTING,
} from 'constants/events/organization';
import {
  CGU_TYPE_ALKEMICS,
  CGU_TYPE_GDSN,
  CGU_TYPE_PRODUCTDNA,
} from 'constants/permissions';
import { getOrganizationId, getOrganizationSource } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_EXP_GDSNPARTICIPATIONTERMS } from 'modules/feature-flag/constants';
import { getProfile, selectUser } from 'modules/user';
import authApi, { fetchCguByType } from 'resources/authApi';
import * as routes from 'routes';
import { push } from 'utils/history';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { createAction } from 'utils/redux';
import { track } from 'utils/tracking';

const callOrganizationApi =
  (id, withs = {}) =>
  (dispatch) => {
    const promise = authApi.OrganizationShow(id, withs);
    promise.then((response) => {
      const organization = response.data.data;
      dispatch({ type: RECEIVE_ORGANIZATION, organization });
    });
    return promise;
  };

export const getOrganization = (id) => (dispatch) => {
  const promise = dispatch(callOrganizationApi(id));
  dispatch({ type: GET_ORGANIZATION });
  return promise;
};

export const getMyOrganization = (/* withGlns = false */) =>
  (dispatch, getState) => {
    const { user } = getState();
    const id = getOrganizationId(user);
    const promise = dispatch(
      callOrganizationApi(id, { glnData: true, extraInfo: true }),
    );
    promise.catch((rejection) => {
      if (rejection.status === 403) {
        push(routes.landing);
      }
    });
    return promise;
  };

// edit in store
export const editOrganization = (field, value) => {
  const data = { field, value };
  return { type: EDIT_ORGANIZATION, data };
};

// save
export const saveOrganization = () => (dispatch, getState) => {
  const editedOrg = getState().organization.edited;
  const promise = authApi.OrganizationUpdate(editedOrg);
  promise.then(() => {
    track({
      category: 'organization',
      action: 'organization_updated',
      label: 'details',
    });
    dispatch(getMyOrganization());
    // This is needed to refresh the organization in the user belongsTo.
    dispatch(getProfile(false)); // no cache.
  });
  return promise;
};

export const getOrganizationSetting = (key) => (dispatch) => {
  const promise = authApi.OrganizationSettingGet(key);
  dispatch({ type: START_LOADING });
  promise.then((response) => {
    /** @type {any} */
    const { value } = response.data;
    dispatch({ type: STOP_LOADING });
    dispatch({ type: RECEIVE_ORGANIZATION_SETTING, key, value });
  });
  return promise;
};

export const updateOrganizationSetting = createAction(
  UPDATE_ORGANIZATION_SETTING,
);

export const upsertOrganizationSetting =
  (key, value) => (dispatch, getState) => {
    dispatch({ type: START_LOADING });
    dispatch(updateOrganizationSetting({ key, value }));
    return authApi
      .OrganizationSettingUpsert({ key, value })
      .then(
        (response) => {
          /** @type {any} */
          const { settings } = response.data;
          dispatch(
            notificationSuccess(
              i18n.t(
                'frontproductstream.organization_admin.setting_created.notification',
                { defaultValue: 'The organization setting has been created' },
              ),
            ),
          );
          dispatch({ type: RECEIVE_ORGANIZATION_SETTINGS, settings });
        },
        () => {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.organization_admin.setting_error.notification',
                {
                  defaultValue:
                    'An error occured while upserting the organization setting.',
                },
              ),
            ),
          );
          dispatch(
            updateOrganizationSetting({
              key,
              value: get(getState(), [
                'organization',
                'source',
                'settings',
                key,
              ]),
            }),
          );
        },
      )
      .then(() => {
        dispatch({ type: STOP_LOADING });
      });
  };

export const getOrganizationCgu = () => async (dispatch, getState) => {
  try {
    const user = selectUser(getState());
    const source = getOrganizationSource(user);
    const applyingCgus = [
      fetchCguByType(
        source === OrganizationSource.PRODUCTDNA
          ? CGU_TYPE_PRODUCTDNA
          : CGU_TYPE_ALKEMICS,
      ),
    ];

    if (hasFeature(user, FEATURE_EXP_GDSNPARTICIPATIONTERMS)) {
      applyingCgus.push(fetchCguByType(CGU_TYPE_GDSN));
    }

    const responses = await Promise.all(applyingCgus);
    const error = responses.find((resp) => resp.error);

    if (error) {
      throw error;
    }

    const cgus = responses.map(({ cgu }) => cgu);
    dispatch({
      type: RECEIVE_ORGANIZATION_CGU,
      cgus: keyBy(cgus, 'type'),
    });
  } catch (e) {
    logError('An error occured while trying to get CGUs.', e);
  }
};

export const organizationCguAccepted = (cguType) => ({
  type: ORGANIZATION_CGU_ACCEPTED,
  cguType,
});

export const acceptOrganizationCgu = (organizationId, cgu) => (dispatch) => {
  authApi.OrganizationAcceptCGU(organizationId, cgu.id);
  dispatch(organizationCguAccepted(cgu.type));
};
