import { List } from 'immutable';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { formFieldUpdated, updateEntity } from 'actions/entity';
import { notificationError } from 'actions/notification';
import {
  isRealtimeInternalTaxonomySuggestion,
  isRealtimeKindSuggestion,
  isSuggestion,
} from 'components/ui/form/plugins/validator/utils';
import {
  ENTITY_TYPE_PRODUCTVERSION,
  ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
} from 'constants/entities';
import { DISMISS_SUGGESTION as DISMISS_SUGGESTION_FROM_FIELD } from 'constants/events/suggestions';
import { getReadOnlyFields } from 'core/api/organization-settings';
import { RECEIVE_DISPLAY_GROUPS } from 'modules/display-groups/constants';
import { selectFieldByName } from 'modules/display-groups/selectors';
import { selectOrganizationSettings } from 'modules/user';
import {
  RECEIVE_RULE_RESULTS,
  RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS,
  RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
  RuleApplicationStatus,
  RuleTemplateLabel,
  applyRulesSaga,
  selectValidationResultsByEntity,
} from 'modules/validation';
import {
  selectAvailableLanguages,
  selectCurrentLanguage,
  selectEditedProductVersion,
  selectProductVersionId,
} from 'reducers/productVersion';
import ClassificationApi from 'resources/classificationApi';
import { request } from 'utils/api';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';
import { logError } from 'utils/logging';
import { withCatch } from 'utils/saga';

import {
  ACCEPTED_ALL_SUGGESTIONS,
  ACCEPTED_SUGGESTION,
  ACCEPTING_ALL_SUGGESTIONS,
  ACCEPTING_SUGGESTION,
  // Accept all
  ACCEPT_ALL_SUGGESTIONS,
  // Accept one
  ACCEPT_SUGGESTION,
  DISMISSED_ALL_SUGGESTIONS,
  DISMISSED_SUGGESTION,
  DISMISSING_ALL_SUGGESTIONS,
  DISMISSING_SUGGESTION,
  // Dismiss all
  DISMISS_ALL_SUGGESTIONS,
  // Dismiss one
  DISMISS_SUGGESTION,
  // Diffs computed
  SUGGESTIONS_DIFFS_COMPUTED,
} from './constants';
import { selectSuggestions } from './selectors';

const getSuggestedLabel = (value, templateLabel) => {
  if (!value) {
    return '';
  } else if (templateLabel === RuleTemplateLabel.VALUE) {
    return value;
  } else if (value.has('isConceptualizedBy')) {
    return value.getIn(['isConceptualizedBy', 'label']);
  } else if (value.has('allergenTypeCode')) {
    return value.getIn(['allergenTypeCode', 'label']);
  } else if (value.has('entity')) {
    // for template label allergen case
    return value.getIn(['entity', 'label']);
  } else if (value.has('categoryCode')) {
    return value.getIn(['categoryCode', 'label']);
  }
  return value.get('label');
};

const getModel = (ruleResult) =>
  (ruleResult.getIn(['paths', 'a', 0]) || '').split('.')[0];

const isSpecificForOrganizations = (ruleResult) =>
  ruleResult.getIn(['isSpecificForOrganizations']);

const isDeclinableByLang = (field) => field.declinableBy.kind === 'languages';

function getElementToDisplayIndexes(value, field, currentLanguage) {
  if (!value || !value.length || value.length === 0 || !field) {
    return [];
  }

  if (!isDeclinableByLang(field)) {
    return Array.from(value.keys());
  }

  let index = [];
  value.forEach((elem, i) => {
    if (currentLanguage && get(elem, 'expressedIn.id') === currentLanguage.id) {
      index = [i];
    }
  });

  return index;
}

function getCurrentValue(type, field, model, current, language) {
  if (type === RuleTemplateLabel.SIMPLE) {
    return current ? [current.label] : [];
  }
  if (type === RuleTemplateLabel.VALUE) {
    return current ? [current] : [];
  }

  const currentValue = Array.isArray(current) ? current : [];

  if (type === RuleTemplateLabel.CONCEPTUALIZED) {
    return currentValue.map((e) => e.isConceptualizedBy.label);
  }

  if (type === RuleTemplateLabel.FULL_LIST) {
    return currentValue
      .filter((e) => e.presence)
      .map((e) => e.isConceptualizedBy.label);
  }

  if (type === RuleTemplateLabel.NOTABLEINGREDIENTTYPELIST) {
    return currentValue
      .filter((e) => e.notableIngredientPresenceCode === 0)
      .map((e) => e.notableIngredientTypeCode.label);
  }

  if (type === RuleTemplateLabel.ALLERGENTYPELIST) {
    return currentValue
      .filter((e) => e.allergenPresenceCode === 0)
      .map((e) => e.allergenTypeCode.label);
  }

  if (
    type === RuleTemplateLabel.LANG_DECLINABLE ||
    type === RuleTemplateLabel.ALLERGENCASE
  ) {
    return getElementToDisplayIndexes(current, field, language)
      .map((i) => currentValue[i].data)
      .filter((v) => v);
  }

  if (type === RuleTemplateLabel.MEASURABLE) {
    return getElementToDisplayIndexes(current, field, language)
      .filter(
        (i) =>
          currentValue[i].data && get(currentValue[i], 'expressedIn.label'),
      )
      .map(
        (i) =>
          `${currentValue[i].data} ${get(
            currentValue[i],
            'expressedIn.label',
            '',
          )}`,
      );
  }

  return [];
}

export function* computeNewValue(type, value, model) {
  const productVersion = yield select(selectEditedProductVersion);
  const currentLanguage = yield select(selectCurrentLanguage);
  const current = get(productVersion, model);
  const fieldSelector = yield select(selectFieldByName);
  const field = yield call(fieldSelector, model);

  if (
    type === RuleTemplateLabel.SIMPLE ||
    type === RuleTemplateLabel.INTERNAL_TAXONOMY ||
    type === RuleTemplateLabel.VALUE
  ) {
    return value;
  }

  if (type === RuleTemplateLabel.CONCEPTUALIZED) {
    return [...(current || []), value];
  }

  if (type === RuleTemplateLabel.FULL_LIST) {
    const newValue = [...(current || [])];
    const valueIndex = newValue.findIndex(
      (e) => e.isConceptualizedBy.id === value.isConceptualizedBy.id,
    );
    if (valueIndex > -1) {
      newValue[valueIndex].presence = true;
    } else {
      const newItem = value;
      newItem.presence = true;
      newValue.push(newItem);
    }
    return newValue;
  }

  if (type === RuleTemplateLabel.NOTABLEINGREDIENTTYPELIST) {
    const newValue = [...(current || [])];

    const valueIndex = newValue.findIndex(
      (e) =>
        e.notableIngredientTypeCode.id === value.notableIngredientTypeCode.id,
    );
    newValue.splice(valueIndex, 1, value);
    return newValue;
  }

  if (type === RuleTemplateLabel.ALLERGENTYPELIST) {
    const newValue = [...(current || [])];

    const valueIndex = newValue.findIndex(
      (e) => e.allergenTypeCode.id === value.allergenTypeCode.id,
    );
    newValue.splice(valueIndex, 1, value);
    return newValue;
  }

  if (type === RuleTemplateLabel.LANG_DECLINABLE) {
    const elementToDisplayIndexes = getElementToDisplayIndexes(
      current,
      field,
      currentLanguage,
    );
    const newValue = [...(current || [])];
    elementToDisplayIndexes.forEach((i) => {
      newValue[i] = { ...newValue[i] };
      let data = newValue[i].data || '';

      // Remove trailing punctuation and spaces
      data = data.replace(/[,. ;]*$/, '');

      // Add a comma + space if necessary
      if (data.length) {
        data += ', ';
      }

      data += value.label;
      newValue[i].data = data;
    });
    return newValue;
  }

  if (type === RuleTemplateLabel.MEASURABLE) {
    const elementToDisplayIndexes = getElementToDisplayIndexes(
      current,
      field,
      currentLanguage,
    );
    const newValue = [...(current || [])];
    elementToDisplayIndexes.forEach((i) => {
      newValue[i] = { ...newValue[i] };
      newValue[i].data = value.data;
      newValue[i].expressedIn = value.expressedIn;
    });
    return newValue;
  }

  if (type === RuleTemplateLabel.ALLERGENCASE) {
    const elementToDisplayIndexes = getElementToDisplayIndexes(
      current,
      field,
      currentLanguage,
    );
    const newValue = [...(current || [])];
    elementToDisplayIndexes.forEach((i) => {
      newValue[i] = { ...newValue[i] };
      let data = newValue[i].data || '';

      // Make the string at given indices upper case
      for (const indice of value.indices) {
        data = data.replace(
          data.slice(indice[0], indice[1]),
          data.slice(indice[0], indice[1]).toUpperCase(),
        );
      }
      newValue[i].data = data;
    });

    return newValue;
  }

  return null;
}

export function* acceptSuggestion(ruleResult, refreshRules) {
  const currentLanguage = yield select(selectCurrentLanguage);
  const model = getModel(ruleResult);
  const suggestedValue = ruleResult.getIn([
    'data',
    'suggestedValueByLanguage',
    currentLanguage.code,
  ]);
  const templateLabel = ruleResult.get('templateLabel');
  const fieldName = getSuggestedLabel(suggestedValue, templateLabel);

  try {
    yield call(request, ClassificationApi, 'AcceptFieldSuggestion', {
      product_id: ruleResult.get('productId'),
      suggestion_entity_id: ruleResult.get('id'),
      fieldmetadata_id: ruleResult.get('fieldmetadataId'),
      field_id: ruleResult.get('fieldId'),
      field_name: fieldName,
      language_id: currentLanguage.id,
    });
  } catch (e) {
    logError(e);
    yield put(
      notificationError(
        i18n.t('Something went wrong while accepting the suggestion'),
      ),
    );

    // There was an error, just bail out
    return;
  }
  const value = toJsIfImmutable(suggestedValue);
  if (!value || !model) {
    return;
  }

  const newValue = yield call(computeNewValue, templateLabel, value, model);

  const specificForOrganizations = isSpecificForOrganizations(ruleResult);
  if (specificForOrganizations) {
    for (const orgId of specificForOrganizations) {
      yield put(
        updateEntity(
          model,
          newValue,
          orgId,
          ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
          true, // isDirty,
          true, // ignoreField
        ),
      );
    }
  } else {
    const productVersionId = yield select(selectProductVersionId);
    yield put(
      updateEntity(
        model,
        newValue,
        productVersionId,
        ENTITY_TYPE_PRODUCTVERSION,
        true, // isDirty,
        true, // ignoreField
      ),
    );
  }
  yield put(formFieldUpdated(model));

  // Refresh rule results
  if (refreshRules) {
    const availableLanguages = yield select(selectAvailableLanguages);
    yield call(applyRulesSaga, {
      payload: {
        entityKind: ENTITY_TYPE_PRODUCTVERSION,
        languages: availableLanguages,
      },
    });
  }
}

export function* acceptSuggestionSaga({ payload: ruleResult }) {
  const model = getModel(ruleResult);
  yield put({
    type: ACCEPTING_SUGGESTION,
    model,
    ruleId: ruleResult.get('id'),
  });
  yield call(acceptSuggestion, ruleResult, true);
  yield put({ type: ACCEPTED_SUGGESTION, model, ruleId: ruleResult.get('id') });
}

export function* dismissSuggestion(ruleResult, refreshRules) {
  const currentLanguage = yield select(selectCurrentLanguage);
  const suggestedValue = ruleResult.getIn([
    'data',
    'suggestedValueByLanguage',
    currentLanguage.code,
  ]);
  const templateLabel = ruleResult.get('templateLabel');
  const fieldName = getSuggestedLabel(suggestedValue, templateLabel);

  try {
    yield call(request, ClassificationApi, 'RefuseFieldSuggestion', {
      product_id: ruleResult.get('productId'),
      suggestion_entity_id: ruleResult.get('id'),
      fieldmetadata_id: ruleResult.get('fieldmetadataId'),
      field_id: ruleResult.get('fieldId'),
      field_name: fieldName,
      language_id: currentLanguage.id,
    });

    if (refreshRules) {
      // Refresh rule results
      const availableLanguages = yield select(selectAvailableLanguages);
      yield call(applyRulesSaga, {
        payload: {
          entityKind: ENTITY_TYPE_PRODUCTVERSION,
          languages: availableLanguages,
        },
      });
    }
  } catch (e) {
    yield put(
      notificationError(
        i18n.t('Something went wrong while dismissing the suggestion'),
      ),
    );
  }
}

export function* dismissSuggestionSaga({ payload: ruleResult }) {
  const model = getModel(ruleResult);
  yield put({
    type: DISMISSING_SUGGESTION,
    model,
    ruleId: ruleResult.get('id'),
  });

  yield call(dismissSuggestion, ruleResult, true);

  yield put({
    type: DISMISSED_SUGGESTION,
    model,
    ruleId: ruleResult.get('id'),
  });
}

export function* acceptAllSuggestionsSaga() {
  yield put({ type: ACCEPTING_ALL_SUGGESTIONS });

  const diffs = yield select(selectSuggestions);
  yield all(
    diffs
      .map((d) => d.suggestions)
      .flatten()
      .map((s) => call(acceptSuggestion, s.rule, false))
      .toArray(),
  );

  // Refresh rule results
  const availableLanguages = yield select(selectAvailableLanguages);
  yield call(applyRulesSaga, {
    payload: {
      entityKind: ENTITY_TYPE_PRODUCTVERSION,
      languages: availableLanguages,
    },
  });

  yield put({ type: ACCEPTED_ALL_SUGGESTIONS });
}

export function* dismissAllSuggestionsSaga() {
  yield put({ type: DISMISSING_ALL_SUGGESTIONS });

  const diffs = yield select(selectSuggestions);
  yield all(
    diffs
      .map((d) => d.suggestions)
      .flatten()
      .map((s) => call(dismissSuggestion, s.rule, false))
      .toArray(),
  );

  // Refresh rule results
  const availableLanguages = yield select(selectAvailableLanguages);
  yield call(applyRulesSaga, {
    payload: {
      entityKind: ENTITY_TYPE_PRODUCTVERSION,
      languages: availableLanguages,
    },
  });

  yield put({ type: DISMISSED_ALL_SUGGESTIONS });
}

export function computeSuggestionsDiffs(
  ruleResults,
  fieldFromName,
  productVersion,
  language,
  settings,
) {
  const rulesMap = (ruleResults || List())
    // merge all results into one big map of rules
    .reduce((acc, results) => acc.concat(results.get('rules')), List())
    // keep only the non-null rules
    .filter((r) => r)
    // display only if language is set
    .filter(() => language)
    // keep only the suggestion that the user can accept on that language (if language is set)
    .filter((r) =>
      r.getIn(['error_by_language', language.code, 'errorMessage']),
    )
    // keep only the suggestions
    .filter(isSuggestion)
    // keep only editable fields
    .filter(
      (s) => !getReadOnlyFields(settings).includes(s.get('fieldmetadataId')),
    )
    // keep only suggestions with suggested values
    .filter((r) => r.getIn(['data', 'suggestedValueByLanguage', language.code]))
    // do not keep realtime Kind suggestions as we propose multiple choices for one unique value
    .filter(
      (r) =>
        !(
          isRealtimeKindSuggestion(r) || isRealtimeInternalTaxonomySuggestion(r)
        ),
    )
    // do not show notable ingredients to avoid spamming
    .filter(
      (r) =>
        r.get('templateLabel') !== RuleTemplateLabel.NOTABLEINGREDIENTTYPELIST,
    )
    .filter((r) => r.get('status') === RuleApplicationStatus.KO);

  const diffs = rulesMap
    .groupBy((r) => getModel(r))
    .map((rules, model) => {
      const field = fieldFromName(model);
      const current = get(productVersion, model);
      const currentValue = getCurrentValue(
        rules.first().get('templateLabel'),
        field,
        model,
        current,
        language,
      );
      const suggestions = rules.map((r) => ({
        id: r.get('id'),
        message: r
          .getIn(['error_by_language', language.code, 'errorMessage'])
          .replace(i18n.t('Suggested value: '), ''),
        rule: r,
        field: field || {},
        model,
      }));
      return {
        field,
        current: currentValue,
        suggestions,
      };
    })
    .filter((d) => get(d, 'field.label'))
    .sort((a, b) => a.field.rank - b.field.rank);
  return List(diffs.values());
}

export function* computeSuggestionsDiffsSaga() {
  const ruleResults = yield select(selectValidationResultsByEntity);
  const productVersion = yield select(selectEditedProductVersion);
  const fieldFromName = yield select(selectFieldByName);
  const currentLanguage = yield select(selectCurrentLanguage);
  const settings = yield select(selectOrganizationSettings);
  const diffs = yield call(
    computeSuggestionsDiffs,
    ruleResults,
    fieldFromName,
    productVersion,
    currentLanguage,
    settings,
  );

  yield put({ type: SUGGESTIONS_DIFFS_COMPUTED, diffs });
}

export default function* productSuggestionsMainSaga() {
  yield takeEvery(
    [DISMISS_SUGGESTION, DISMISS_SUGGESTION_FROM_FIELD],
    withCatch(dismissSuggestionSaga),
  );
  yield takeEvery(ACCEPT_SUGGESTION, withCatch(acceptSuggestionSaga));

  yield takeLatest(
    [
      RECEIVE_RULE_RESULTS,
      RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
      RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS,
      RECEIVE_DISPLAY_GROUPS,
    ],
    withCatch(computeSuggestionsDiffsSaga),
  );

  yield takeLatest(ACCEPT_ALL_SUGGESTIONS, withCatch(acceptAllSuggestionsSaga));
  yield takeLatest(
    DISMISS_ALL_SUGGESTIONS,
    withCatch(dismissAllSuggestionsSaga),
  );
}
