import { Supplier } from 'modules/supplier-directory/types';
import { createAction } from 'utils/redux';

import {
  CLOSE_MODAL,
  FETCH_SUPPLIER_NOT_RECONCILED,
  FETCH_SUPPLIER_NOT_RECONCILED_SUCCESS,
  GET_REFUSED_INFORMATION,
  // Modal
  OPEN_MODAL,
  SEND_MESSAGE_DONE,
  SEND_MESSAGE_IN_PROGRESS,
  SEND_MESSAGE_START,
} from './events';
import {
  MessageToSend,
  RefusedRecipient,
  SendMessageInProgressActionPayload,
} from './types';

export const openModal = (typeModal: string) => ({
  type: OPEN_MODAL,
  typeModal,
});

export const closeModal = createAction(CLOSE_MODAL);

export const fetchSupplier = (supplierId: number | null) => ({
  type: FETCH_SUPPLIER_NOT_RECONCILED,
  supplierId,
});

export const fetchSupplierSuccess = (
  supplier: Supplier | null,
  loading: boolean,
) => ({
  type: FETCH_SUPPLIER_NOT_RECONCILED_SUCCESS,
  supplier,
  loading,
});

export const getRefusedInformation = (
  refusedRecipients: RefusedRecipient[] | null,
  gtin: string | null,
) => ({
  type: GET_REFUSED_INFORMATION,
  refusedRecipients,
  gtin,
});

export const sendMessageStart = (messagesToSend: MessageToSend[]) => ({
  type: SEND_MESSAGE_START,
  messagesToSend,
});

export const sendMessageInProgress = (
  messageStatus: SendMessageInProgressActionPayload,
) => ({
  type: SEND_MESSAGE_IN_PROGRESS,
  messageStatus,
});

export const sendMessageDone = createAction(SEND_MESSAGE_DONE);
